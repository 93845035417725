@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: url("./Benways.png");
  background-size: cover;
  animation: spin 2s linear infinite;
}

body,
html {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Couleur de fond pour contraste */
}
